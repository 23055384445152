/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


// any CSS you require will output into a single css file (app.css in this case)
require("../scss/main.scss");

require('jquery-ui');

require('bootstrap');

import 'owl.carousel2';

var AOS = require('aos');
var Swal = require('sweetalert2');
var Mmenulight = require('mmenu-light');
require('mmenu-js');
require('mhead-js');

var site = '/';

$(document).ready(function () {

    _msieversion();

    if(_msieversion() === false)
    {
        $("#menu").mmenu({
            extensions 	: [ "theme-white", "border-full", "shadow-page", "position-right"]}, { });
    }
    else
    {
        $('#ie-alert').show();
        $('#menu').hide();
    }

    $("#content header").mhead({
        scroll: {
            hide: 200
        }
    });

    $(".mh-head:not(.mm-sticky)").mhead({
        scroll: false
    });

    if (typeof success !== 'undefined')
    {
        if(success == '1')
        {
            _popupSuccess('success', 'Bien joué !', 'Votre message a été envoyé.');
        }
    }

    AOS.init({
        duration: 1200,
    });

    // carrousel ekomi
    $(".owl-carousel-ekomi").owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        autoplay: true,
        center: true
    });


    // carrousel partenaire
    $('#partner .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        autoplay: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 5,
                nav: false
            }
        }
    });

    var width = $(window).width();

    if (width >= '1024') {
        $(window).scroll(function () {
            var page_offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            if (page_offset > 50) {
                $("#scroll-down").fadeOut(500);
            } else {
                $("#scroll-down").fadeIn(500);
            }
        });

        $('#scroll-down').on('click', function () {
            var top = $('#quality').offset().top - 100;
            var speed = 750;

            $('html, body').animate({scrollTop: (top)}, speed);

            return false;
        });
    }

    // Envoi parrainage
    $('#sponsorshipForm').on('submit', function (event) {
        $.ajax({
            url: url,
            data: $(this).serialize(),
            method: 'POST'
        }).done(function () {
            _popupSuccess('success', 'C\'est parti !', 'Merci de votre confiance')
        });
        event.preventDefault();
    });

    window.onscroll = function() {_scrollFunction()};

    // Gestion des envois des formulaires de la page assistante
    $('#accordion form, #accordion2 form').on('submit', function (event) {
        event.preventDefault();

        var data = new FormData(this);
        var files = $(this).find('input[type=file]')[0].files;

        [].forEach.call(files, function (file) {
            data.append('files[]', file);
        });

        $.ajax({
            url: url,
            data: data,
            method: 'POST',
            contentType: false,
            processData: false,
        }).done(function () {
            //$('#accordion, #accordion2').collapse('hide');
            $('button[aria-expanded="true"]').trigger('click');

            _popupSuccess('success', 'Merci !', 'Votre demande a été envoyée.')
        });
    });

});

var _scrollFunction = function () {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        $(".topbar-menu").css("top", 0);
    } else {
        $(".topbar-menu").css("top", "-100px");
    }
}

var _popupSuccess = function(status, title, text)
{
    Swal.fire({
        title: '<strong>' + title + '</strong>',
        type: status,
        html: text
    });
}

var _msieversion = function () {

    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if(isIE11)
    {
        return true;
    }
    return false;
};